<template>
  <div class="intro-y box">
    <app-city-table-page ref="city"></app-city-table-page>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CityTablePage from '@/views/reports/city-table-page.vue'

import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  components: {
    [CityTablePage.name]: CityTablePage
  },
  data() {
    return {
      dateRange: ''
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    }),
    dateRangeValue() {
      let firstDay, lastDay
      if (this.dateRange) {
        const dates = this.dateRange.split(' - ')
        firstDay = dayjs(dates[0])
          .utc(true)
          .format()
        lastDay = dayjs(dates[1])
          .utc(true)
          .format()
      } else {
        firstDay = dayjs()
          .startOf('month')
          .utc(true)
          .format()
        lastDay = dayjs()
          .endOf('month')
          .utc(true)
          .format()
      }

      return {
        startDate: firstDay,
        endDate: lastDay
      }
    }
  },
  async mounted() {
    this.$refs.city.doFilter()
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    doResetDateRange() {
      const firstDate = dayjs()
        .startOf('month')
        .format('D MMM, YYYY')
      const lastDate = dayjs()
        .endOf('month')
        .format('D MMM, YYYY')
      this.dateRange = `${firstDate} - ${lastDate}`
    },
    doFilter() {
      this.$refs.city.doFilter(this.dateRangeValue)
    },
    doResetFilter() {
      this.doResetDateRange()
      this.doFilter()
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
