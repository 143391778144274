<template>
  <div>
    <div class="intro-y">
      <div class="flex justify-between flex-wrap gap-6 mt-5 p-3 pb-10">
        <div class="flex-1 intro-y">
          <app-sales-card
            :label="i18n('reports.salesReport.total')"
            :amount="total"
            amount-style="color: rgba(49, 96, 216, 1) !important;"
          ></app-sales-card>
        </div>
        <div class="flex-1 intro-y">
          <app-sales-card
            :label="i18n('reports.salesReport.taxes')"
            :amount="taxes"
            amount-style="color: #CE3131 !important;"
          ></app-sales-card>
        </div>
        <div class="flex-1 intro-y">
          <app-sales-card
            :label="i18n('reports.salesReport.chefsPayment')"
            :amount="chefsPayment"
          ></app-sales-card>
        </div>
        <div class="flex-1 intro-y">
          <app-sales-card
            :label="i18n('reports.salesReport.captainsPayment')"
            :amount="captainsPayment"
          ></app-sales-card>
        </div>
        <div class="flex-1 intro-y">
          <app-sales-card
            :label="i18n('reports.salesReport.profit')"
            :amount="profit"
            amount-style="color: rgb(22, 130, 62) !important;"
          ></app-sales-card>
        </div>
      </div>
      <app-order-table-page
        ref="orders"
        @filter="doFindSales"
      ></app-order-table-page>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SalesCard from '@/views/reports/sales-card.vue'
import OrderTablePage from '@/views/reports/order-table-page.vue'

import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  components: {
    [SalesCard.name]: SalesCard,
    [OrderTablePage.name]: OrderTablePage
  },
  data() {
    return {
      dateRange: '',
      dateRangeFrom: '',
      dateRangeTo: '',
      datePickerOptionsFrom: {
        // lang: this.language,
        autoApply: true,
        autoRefresh: true,
        format: 'YYYY-MM-DD',
        showWeekNumbers: true,
        dropdowns: {
          minYear: 1990,
          maxYear: null,
          months: true,
          years: true
        }
      },
      datePickerOptionsTo: {
        // lang: this.language,
        autoApply: true,
        format: 'YYYY-MM-DD',
        autoRefresh: true,
        showWeekNumbers: true,
        lockDays: [['1990-1-1', this.dateRangeFrom]],
        dropdowns: {
          minYear: 1990,
          maxYear: null,
          months: true,
          years: true
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'report/sales/record',
      loading: 'report/sales/loading'
    }),
    total() {
      return this.record ? this.record.total : 0
    },
    taxes() {
      return this.record ? this.record.taxes : 0
    },
    chefsPayment() {
      return this.record ? this.record.chefsPayment : 0
    },
    captainsPayment() {
      return this.record ? this.record.captainsPayment : 0
    },
    profit() {
      return this.record ? this.record.profit : 0
    },
    dateRangeValue() {
      const firstDay = this.dateRangeFrom
        ? dayjs(this.dateRangeFrom)
            .utc(true)
            .format()
        : dayjs()
            .startOf('month')
            .utc(true)
            .format()

      const lastDay = this.dateRangeTo
        ? dayjs(this.dateRangeTo)
            .utc(true)
            .format()
        : dayjs()
            .endOf('month')
            .utc(true)
            .format()
      return {
        startDate: firstDay,
        endDate: lastDay
      }
    }
  },
  created() {
    this.doResetDateRange()
  },
  async mounted() {
    this.$refs.orders.doFetchFirstPage()
    this.doFindSales()
  },
  watch: {
    language(val) {
      this.datePickerOptionsFrom.lang = val
      this.datePickerOptionsTo.lang = val
    }
  },
  methods: {
    ...mapActions({
      doFind: 'report/sales/doFind'
    }),
    handleDatePickerFromSelect(date) {
      this.datePickerOptionsTo.lockDays = [['1990-1-1', date]]
      this.dateRangeTo = date
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    doResetDateRange() {
      const firstDate = dayjs()
        .startOf('month')
        .format('D MMM, YYYY')
      const lastDate = dayjs()
        .endOf('month')
        .format('D MMM, YYYY')
      this.dateRange = `${firstDate} - ${lastDate}`
    },
    doFindSales(filter) {
      let dateRangeValue
      if (!filter) {
        dateRangeValue = {
          startDate: '1990-01-01T00:00:00Z',
          endDate: dayjs()
            .endOf('month')
            .utc(true)
            .format()
        }
      } else {
        dateRangeValue = {
          startDate: filter[0].value,
          endDate: filter[1].value
        }
      }
      this.doFind(dateRangeValue)
    },
    doResetFilter() {
      this.doResetDateRange()
      this.doFilter()
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
