<template>
  <div>
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y box col-span-12 lg:col-span-12 p-5">
        <div
          class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
        >
          <app-action-toolbar
            @onPrint="onPrint()"
            @onExportXlsx="onExportXlsx()"
            @onExportAllXlsx="onExportAllXlsx()"
            @onDownloadPdf="onDownloadPdf()"
          ></app-action-toolbar>
        </div>
        <div ref="table" class="relative w-full">
          <loading-spinner
            type="half-circle"
            :duration="1000"
            :size="40"
            color="#4469A8"
            :loading="loading"
          />
          <div class="overflow-x-auto">
            <table
              id="orderTable"
              ref="tableRef"
              class="table table-report sm:mt-2 print-table"
            >
              <thead>
                <tr>
                  <th
                    v-for="col in columns"
                    :key="col.name"
                    class="text-center whitespace-nowrap"
                  >
                    {{ i18n(col.label) }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length">
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                  class="intro-x"
                  :class="{ removedRow: row.isRemoved }"
                >
                  <td class="text-center">
                    <router-link
                      :to="`/orders/${row.id}`"
                      target="_blank"
                      class="font-medium whitespace-wrap text-theme-10 dark:text-theme-30"
                    >
                      {{ row.orderNo ? `#${row.orderNo}` : 'ـــ' }}
                    </router-link>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row.chef, 'code') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row.captain, 'code') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row.client, 'code') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      class="font-medium whitespace-nowrap text-theme-10 dark:text-theme-30"
                    >
                      {{ presenterPrice(row.totalAmount) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterDate(row, 'createdAt') }}
                    </div>
                    <div class="font-small text-xs whitespace-nowrap mt-1">
                      {{ presenterTime(row, 'createdAt') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      class="font-medium whitespace-nowrap text-theme-10 dark:text-theme-30"
                    >
                      {{ presenterPrice(row.cartAmount) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap text-theme-10">
                      {{ presenterPrice(row.chefProfit) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap text-theme-10">
                      {{ presenterPrice(row.captainProfit) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      class="font-medium whitespace-nowrap text-green-600"
                      style="color: rgb(22, 130, 62) !important"
                    >
                      {{ presenterPrice(row.appOrderProfit) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap text-theme-10">
                      {{ presenterPrice(row.deliveryFees) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap text-theme-10">
                      {{ presenterPrice(row.appDeliveryProfit) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap text-theme-10">
                      {{ presenterPrice(row.taxes) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap text-theme-10">
                      {{ presenterPrice(row.appTotalProfit) }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-empty-page
              v-if="!rows.length && !loading"
              :label="i18n('empty.order')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-64"
            ></app-empty-page>
          </div>
          <Pagination
            v-if="rows.length"
            :page="currentPage"
            :pageSize="pagination.limit"
            :rowsNumber="pagination.total"
            :showSizeChanger="true"
            :disabled="!rows.length"
            position="center"
            @next-page="doNextPage"
            @prev-change="doPrevPage"
            @size-change="doChangePaginationPageSize"
          ></Pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { helper as $h } from '@/utils/helper'
import ActionToolbar from '@/global-components/action-toolbar.vue'
import { presenterMixin } from '@/mixins/presenter-mixin'
import { exportPrintMixin } from '@/mixins/export-print-mixin'

export default {
  name: 'app-order-table-page',
  mixins: [presenterMixin, exportPrintMixin],
  components: {
    [ActionToolbar.name]: ActionToolbar
  },
  data() {
    return {
      // data important for Mixins
      tableRef: null,
      exportTableName: 'orders',
      exportResourceName: 'order',
      excludedCols: [],
      ignoreElements: [],
      // component Data
      defaultFilter: { key: 'status', operator: 'equal', value: 'closed' },
      filterInput: [],
      columns: [
        {
          name: 'orderNo',
          field: 'orderNo',
          label: 'order.fields.orderNumber',
          align: 'center'
        },
        {
          name: 'chefCode',
          field: 'chefCode',
          label: 'iam.fields.chefCode',
          align: 'center'
        },
        {
          name: 'captainCode',
          field: 'captainCode',
          label: 'iam.fields.captainCode',
          align: 'center'
        },
        {
          name: 'clientCode',
          field: 'clientCode',
          label: 'iam.fields.clientCode',
          align: 'center'
        },
        {
          name: 'amount',
          field: 'amount',
          label: 'order.fields.total',
          align: 'center'
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'order.fields.createdAt',
          align: 'center'
        },
        {
          name: 'cartAmount',
          field: 'cartAmount',
          label: 'order.fields.cartAmount',
          align: 'center'
        },
        {
          name: 'chefProfit',
          field: 'chefProfit',
          label: 'order.fields.chefProfit',
          align: 'center'
        },
        {
          name: 'captainProfit',
          field: 'captainProfit',
          label: 'order.fields.captainProfit',
          align: 'center'
        },
        {
          name: 'appOrderProfit',
          field: 'appOrderProfit',
          label: 'order.fields.appOrderProfit',
          align: 'center'
        },
        {
          name: 'deliveryFees',
          field: 'deliveryFees',
          label: 'order.fields.deliveryFees',
          align: 'center'
        },
        {
          name: 'appDeliveryProfit',
          field: 'appDeliveryProfit',
          label: 'order.fields.appDeliveryProfit',
          align: 'center'
        },
        {
          name: 'taxes',
          field: 'taxes',
          label: 'order.fields.taxes',
          align: 'center'
        },
        {
          name: 'netProfit',
          field: 'netProfit',
          label: 'order.fields.netProfit',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      rows: 'order/list/rows',
      pagination: 'order/list/pagination',
      listLoading: 'order/list/loading',
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      exportLoading: 'exportStore/loading'
    }),
    loading() {
      return this.listLoading || this.exportLoading
    },
    filterFields() {
      return [
        {
          label: this.i18n('reports.dateRange'),
          value: 'createdAt',
          type: 'date-range'
        }
      ]
    },
    currentPage() {
      return this.pagination.currentPage || 1
    }
  },
  methods: {
    ...mapActions({
      doFetch: 'order/list/doFetch',
      doFetchNextPage: 'order/list/doFetchNextPage',
      doFetchPreviousPage: 'order/list/doFetchPreviousPage',
      doMountTable: 'order/list/doMountTable',
      doChangeSort: 'order/list/doChangeSort',
      doChangePaginationCurrentPage: 'order/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'order/list/doChangePaginationPageSize',
      doExport: 'exportStore/doExport'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    async doFetchFirstPage(orderBy) {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: [this.defaultFilter, ...this.filterInput],
        pagination: {
          firstPage: true,
          limit: 50,
          action: 'next',
          orderBy: orderBy || 'createdAt',
          sortType: 'desc'
        }
      })
    },
    async doFilter(filter, orderBy) {
      this.filterInput = []
      if (!filter) return
      if (Array.isArray(filter)) {
        this.filterInput.push(...filter)
      } else {
        this.filterInput.push(filter)
      }
      this.$emit('filter', filter)
      await this.doFetchFirstPage(orderBy)
    },
    async doResetFilter() {
      this.filterInput = []
      this.$emit('filter', null)
      await this.doFetchFirstPage('createdAt')
    },
    onDownloadPdf() {
      $h.downloadPdf(
        '#orderTable',
        'Sales-Report',
        [],
        {
          fontSize: 8, // Adjust font size
          valign: 'middle',
          halign: 'center',
          cellPadding: 2 // Adjust cell padding
        },
        { valign: 'middle', halign: 'center' },
        { align: 'center' }
      )
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
