<template>
  <div>
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter>
    <div ref="table" class="relative w-full">
      <loading-spinner
        type="half-circle"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading"
      />
      <div class="overflow-x-auto">
        <table ref="tabulator" class="table table-report sm:mt-2">
          <thead>
            <tr>
              <th
                v-for="col in columns"
                :key="col.name"
                class="text-center whitespace-nowrap"
              >
                {{ i18n(col.label) }}
              </th>
            </tr>
          </thead>
          <tbody v-if="rows.length">
            <tr
              v-for="(row, index) in rows"
              :key="index"
              class="intro-x"
              :class="{ removedRow: row.isRemoved }"
            >
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenterLocalization(row, 'city') }}
                </div>
              </td>
              <td class="text-center">
                <div
                  class="font-medium whitespace-nowrap text-theme-10 dark:text-theme-30"
                >
                  {{ presenterInteger(row, 'ordersCount') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenterDecimal(row, 'chefsProfit') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenterDecimal(row, 'captainsProfit') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenterDecimal(row, 'appProfit') }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <app-empty-page
          v-if="!rows.length && !loading"
          :label="i18n('empty.city')"
          label-class="text-lg font-medium"
          icon-size="4x"
          class="box w-full py-64"
        ></app-empty-page>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'
import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  name: 'app-city-table-page',
  data() {
    return {
      filterInput: [],
      tableRef: null,
      columns: [
        {
          name: 'city',
          field: 'city',
          label: 'reports.citiesReport.table.city',
          align: 'center'
        },
        {
          name: 'orderCount',
          field: 'orderCount',
          label: 'reports.citiesReport.table.numberOfOrders',
          align: 'center'
        },
        {
          name: 'chefProfit',
          field: 'chefProfit',
          label: 'reports.citiesReport.table.chefProfit',
          align: 'center'
        },
        {
          name: 'captainProfit',
          field: 'captainProfit',
          label: 'reports.citiesReport.table.captainProfit',
          align: 'center'
        },
        {
          name: 'appProfit',
          field: 'appProfit',
          label: 'reports.citiesReport.table.appProfit',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      rows: 'report/cities/rows',
      loading: 'report/cities/loading',
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    }),
    filterFields() {
      return [
        {
          label: this.i18n('reports.dateRange'),
          value: 'createdAt',
          type: 'date-range'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      doFetch: 'report/cities/doFetch'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar' ? `${PRICE} ريال` : `${PRICE} SAR`
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterTime(row, fieldName) {
      return GenericModel.presenterTime(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      const val = row != null ? row[fieldName] : null
      const valueEn = val && typeof val === 'object' ? val.en : null
      const valueAr = val && typeof val === 'object' ? val.ar : null
      const value =
        this.language === 'ar'
          ? valueAr != null
            ? valueAr
            : valueEn
          : valueEn != null
          ? valueEn
          : valueAr
      return value != null ? value : 'ـــ'
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options
      const val = options.filter(el => el.id == row[fieldName])
      return val.length ? val[0].label : 'ـــ'
    },
    async doResetFilter() {
      await this.doFilter()
    },
    async doFilter(filter) {
      let dateRangeValue
      if (!filter) {
        dateRangeValue = {
          startDate: '1990-01-01T00:00:00Z',
          endDate: dayjs()
            .endOf('month')
            .utc(true)
            .format()
        }
      } else {
        dateRangeValue = {
          startDate: filter[0].value,
          endDate: filter[1].value
        }
      }
      await this.doFetch(dateRangeValue)
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
