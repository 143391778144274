<template>
  <section>
    <div class="report-box zoom-in" style="cursor: unset !important;" v-if="!loading">
      <div class="box p-5 dark:bg-dark-4">
        <div class="flex">
          <div>
            <div class="label-font text-3xl font-medium leading-8">
              <app-i18n :code="label"></app-i18n>
            </div>
            <div
              class="amount-font text-lg font-medium text-gray-600 dark:text-gray-300 mt-5"
              :class="isRTL ? 'mr-2' : 'ml-2'"
              :style="amountStyle"
            >{{ amountValue }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="report-box zoom-in" style="cursor: unset !important;" v-else>
      <div class="box p-5 dark:bg-dark-4">
        <div class="flex-1 space-y-6 py-1">
          <div class="grid grid-cols-6 gap-2">
            <div class="animate-skeleton h-6 bg-slate-200 rounded col-span-4" style="background: #f8f8f8;"></div>
          </div>
          <div class="flex items-center gap-2">
            <div class="animate-skeleton h-4 w-1/2 bg-slate-200 rounded" style="background: #f8f8f8;"></div>
            <div class="animate-skeleton rounded bg-slate-200 h-4 w-8" style="background: #f8f8f8;"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default defineComponent({
  name: 'app-sales-card',
  props: {
    icon: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: true
    },
    amount: {
      type: Number,
      required: true
    },
    availableBalance: {
      type: Number,
      required: false
    },
    'amount-style': {
      type: String,
      default: 'color: #000000 !important;'
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      loading: 'report/sales/loading'
    }),
    amountValue() {
      const PRICE = this.roundNumber(this.amount)
      const amount = this.numberWithCommas(PRICE)
      return this.language == 'ar'
        ? `${amount || 0} ريال سعودي`
        : `${amount || 0} SAR`
    },
    availableBalanceValue() {
      const PRICE = this.roundNumber(this.availableBalance)
      const amount = this.numberWithCommas(PRICE)
      return this.language == 'ar'
        ? `${amount || 0} ريال سعودي`
        : `${amount || 0} SAR`
    }
    // amountColor() {
    //   return this.value.type == 'credit'
    //     ? { color: '#23C045' }
    //     : { color: '#F14336' }
    // },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar'
        ? `${PRICE} ريال`
        : `${PRICE} SAR`
    },
    roundNumber(number) {
      return (Math.round(number * 100) / 100).toFixed(2)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>

<style lang="scss" scoped>
.label-font {
  font-size: 30px !important;
}
.amount-font {
  font-size: 18px !important;
}
@media (max-width: 1439px) {
  .label-font {
    font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1440 - 320))) !important;
  }
  .amount-font {
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1440 - 320))) !important;
  }
}
</style>
