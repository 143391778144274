<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('reports.title') }}</h2>
    </div>
    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start" role="tablist">
        <a
          id="sales-tab"
          data-toggle="tab"
          data-target="#sales"
          href="javascript:;"
          class="py-4 sm:mr-8 active"
          role="tab"
          aria-controls="sales"
          aria-selected="true"
          @click="openTab('sales')"
        >{{ i18n('reports.sales') }}</a>
        <a
          id="cities-tab"
          data-toggle="tab"
          data-target="#cities"
          href="javascript:;"
          class="py-4 sm:mr-8"
          role="tab"
          aria-selected="false"
          @click="openTab('cities')"
        >{{ i18n('reports.cities') }}</a>
      </div>
    </div>
    <!-- END: Profile Info -->

    <div class="intro-y tab-content mt-5">
      <div id="sales" class="tab-pane active" role="tabpanel" aria-labelledby="sales-tab" v-if="tab == 'sales'">
        <SalesReport />
      </div>
      <div id="chefs" class="tab-pane active" role="tabpanel" aria-labelledby="chefs-tab" v-if="tab == 'cities'">
        <CitiesReport />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import SalesReport from '@/views/reports/sales-report.vue'
import CitiesReport from '@/views/reports/cities-report.vue'

export default defineComponent({
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.reports')
  },
  watch: {
    isRTL() {
      document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.reports')
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  components: {
    SalesReport,
    CitiesReport
  },
  setup() {
    const activated = ref(true)
    const tab = ref('sales')
    return {
      activated,
      tab
    }
  },
  methods: {
    openTab(tab) {
      this.tab = tab
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
